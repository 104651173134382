import React, { useContext, useEffect } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import {
	PromotionBar,
	ResourceBar,
	Navbar,
	StandaloneResourceLink,
	MobileNav,
	NavLink,
	MobileNavLink,
	StandaloneNavLink,
	MobileStandaloneNavLink,
} from 'ww-nav-components';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button, makeStyles, Grid } from '@material-ui/core';

import PromotionContext from '../../context/PromotionContext';
import FormModalContext from '../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	appBar: {
		background: theme.white,
		color: theme.workwaveBlue,
		padding: '0 0',
		borderTop: `1.3px ${theme.lightGray} solid`,
		minHeight: '64px',
		display: 'flex',
		justifyContent: 'center',
	},
	cta: {
		fontSize: '20px',
		marginLeft: '10px',
		[theme.breakpoints.down('md')]: {
			fontSize: '16px',
		},
	},
	img: {
		height: '3rem',
		marginTop: '.5rem',
		[theme.breakpoints.down('xs')]: {
			height: '2.5rem',
		},
	},
	resource: {
		display: 'block',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	mobile: {
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	desktop: {
		display: 'contents',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
}));

export const Nav = () => {
	const location = useLocation();
	const { nav } = useStaticQuery(graphql`
		{
			nav: sanityNav {
				title
				promotionBar {
					_type
					_rawContent
				}
				resourceBar {
					mktplcLink
					salesNumber
					serviceNumber
				}
				wwNavLogo {
					asset {
						gatsbyImageData(placeholder: BLURRED, height: 60, width: 225)
						url
					}
				}
				industryNavHeader
				industryLinks {
					title
					navId
					type
					faIcon
					iconColor {
						hexValue
					}
					name
					category
					slug {
						current
					}
					internalLink
				}
				industryMobileFaIcon
				industryCalloutFaIcon
				industryCalloutTitle
				_rawIndustryCalloutContent
				industryCalloutCtas {
					image {
						asset {
							gatsbyImageData(placeholder: BLURRED, height: 200, width: 280)
						}
					}
					_rawContent
					navId
				}
				productLinks {
					title
					navId
					type
					mainName
					secondaryName
					category
					subcategory
					description
					slug {
						current
					}
					internalLink
				}
				productNavHeader
				productMobileFaIcon
				productCalloutIcon
				productCalloutTitle
				_rawProductCalloutContent
				productCalloutCtas {
					image {
						asset {
							gatsbyImageData(placeholder: BLURRED, height: 200, width: 280)
						}
					}
					_rawContent
					navId
					link
					internalLink
				}
				companyLinks {
					title
					navId
					type
					faIcon
					iconColor {
						hexValue
					}
					name
					slug {
						current
					}
					internalLink
				}
				companyNavHeader
				companyMobileFaIcon
				companyCalloutIcon
				companyCalloutTitle
				_rawCompanyCalloutContent
				companyCalloutCtas {
					navId
					image {
						asset {
							gatsbyImageData(placeholder: BLURRED, height: 240, width: 310)
						}
					}
					_rawContent
					link
					internalLink
				}
				salesMobileFaIcon
				salesNavHeader
				salesHeader
				salesLinks {
					title
					navId
					type
					faIcon
					iconColor {
						hexValue
					}
					name
					description
					slug {
						current
					}
				}
				supportNavHeader
				supportMobileFaIcon
				supportHeader
				supportLinks {
					title
					navId
					type
					faIcon
					iconColor {
						hexValue
					}
					name
					description
					slug {
						current
					}
				}
				partnerMobileFaIcon
				partnerNavLink {
					title
					navId
					slug {
						current
					}
				}
				ctaText
				ctaTextNavId
			}
		}
	`);

	//only uncomment to make changes in nav content. nav hover makes it tough to make styling changes. This will freeze the nav open with devtools open after 5 seconds
	// useEffect(() => {
	// 	setTimeout(function () {
	// 		debugger;
	// 	}, 5000);
	// }, []);

	const classes = useStyles();
	const med = useMediaQuery('(max-width: 960px)');

	const { open, setOpen } = useContext(PromotionContext);
	const { handleModalClick } = useContext(FormModalContext);

	const handleSalesClick = (e) => {
		e.preventDefault();
		navigate('/contact-us');
	};

	//for any pages that a nav should not appear, add them to this array
	return !location.pathname.includes(['/uc-toolkit']) ? (
		<>
			{nav.promotionBar && (
				<PromotionBar
					barColor='#d10066'
					ucText
					promotion={nav.promotionBar}
					open={open}
					setOpen={setOpen}
				/>
			)}

			<Grid item className={classes.resource}>
				<ResourceBar>
					{/* <StandaloneResourceLink
						link='https://marketplace.workwave.com'
						title={nav.resourceBar.mktplcLink}
						internal={false}
						icon='shopping-cart'
					/> */}
					<StandaloneResourceLink
						link='tel: 866-794-1658'
						title={nav.resourceBar.salesNumber}
						internal={false}
						icon='phone-alt'
					/>
					<StandaloneResourceLink
						link='tel:  800-762-0301'
						title={nav.resourceBar.serviceNumber}
						internal={false}
						icon='phone-alt'
					/>
				</ResourceBar>
			</Grid>

			<Navbar image={nav.wwNavLogo.asset.gatsbyImageData} role='banner'>
				{/* mobile nav responsive hide gets controlled in ww-nav-components */}
				<MobileNav
					ctaText={nav.ctaText}
					handleModalClick={handleModalClick}
					ctaTextNavId={nav.ctaTextNavId}>
					<MobileNavLink
						header={nav.industryNavHeader}
						links={nav.industryLinks}
						icon={nav.industryMobileFaIcon}
					/>
					<MobileNavLink
						header={nav.productNavHeader}
						links={nav.productLinks}
						icon={nav.productMobileFaIcon}
					/>
					<MobileNavLink
						header={nav.companyNavHeader}
						links={nav.companyLinks}
						icon={nav.companyMobileFaIcon}
					/>
					<MobileNavLink
						header={nav.salesNavHeader}
						links={nav.salesLinks}
						icon={nav.salesMobileFaIcon}
					/>
					<MobileNavLink
						header={nav.supportNavHeader}
						links={nav.supportLinks}
						icon={nav.supportMobileFaIcon}
					/>
					<MobileStandaloneNavLink
						link={nav.partnerNavLink.title}
						title={nav.partnerNavLink.title}
						icon={nav.partnerMobileFaIcon}
						internal={true}
					/>
					<MobileStandaloneNavLink
						link='https://marketplace.workwave.com'
						title={nav.resourceBar.mktplcLink}
						icon='cart-shopping'
						internal={false}
					/>
				</MobileNav>
				<Grid item className={classes.desktop}>
					<NavLink
						header={nav.industryNavHeader}
						links={nav.industryLinks}
						calloutIcon={nav.industryCalloutFaIcon}
						calloutTitle={nav.industryCalloutTitle}
						calloutContent={nav._rawIndustryCalloutContent}
						calloutCtas={nav.industryCalloutCtas}
					/>
					<NavLink
						header={nav.productNavHeader}
						links={nav.productLinks}
						calloutIcon={nav.productCalloutIcon}
						calloutTitle={nav.productCalloutTitle}
						calloutContent={nav._rawProductCalloutContent}
						calloutCtas={nav.productCalloutCtas}
					/>
					<NavLink
						header={nav.companyNavHeader}
						links={nav.companyLinks}
						calloutIcon={nav.companyCalloutIcon}
						calloutTitle={nav.companyCalloutTitle}
						calloutContent={nav._rawCompanyCalloutContent}
						calloutCtas={nav.companyCalloutCtas}
					/>
					<NavLink
						header={nav.salesNavHeader}
						contentHeader={nav.salesHeader}
						links={nav.salesLinks}
						handleModalClick={handleSalesClick}
						internalLink={false}
					/>
					<NavLink
						header={nav.supportNavHeader}
						contentHeader={nav.supportHeader}
						links={nav.supportLinks}
						internalLink={true}
						handleModalClick={handleModalClick}
					/>
					<StandaloneNavLink
						link={nav.partnerNavLink.slug.current}
						title={nav.partnerNavLink.title}
						internal={true}
						icon={false}
						navId={nav.partnerNavLink.navId}
					/>
					<Button
						variant='contained'
						size='medium'
						color='secondary'
						onClick={(e) => handleModalClick(e)}
						className={classes.cta}
						id='modal-button'>
						{nav.ctaText}
					</Button>
				</Grid>
			</Navbar>
		</>
	) : null;
};
