import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useLocation } from '@reach/router';

import {
	AppBar,
	Toolbar,
	Typography,
	Container,
	Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	footer: {
		background: '#f4f8ff',
		color: '#002D5C',
		overflowX: 'hidden',
		marginTop: '-8px',
		boxShadow: 'none',
	},
	headerText: {
		fontWeight: 'bold',
		marginBottom: '1rem',
		textAlign: 'left',
	},
	linkText: {
		textDecoration: 'none',
		marginBottom: '10px',
		color: '#002D5C',
		fontSize: 16,
		textAlign: 'left',
		opacity: '1',
		transition: 'opacity .25s ease-in-out',
		'&:hover': {
			opacity: '0.6',
		},

		[theme.breakpoints.down('lg')]: {
			minHeight: 'auto',
			minWidth: 'auto',
		},
	},
	linkTextSocial: {
		opacity: '1',
		maxWidth: '25px',
		transition: 'opacity .25s ease-in-out',
		'&:hover': {
			opacity: '0.6',
		},
	},
	linkTextAddress: {
		textDecoration: 'none',
		marginBottom: '10px',
		color: '#002D5C',
		fontSize: 16,
		textAlign: 'left',
		// opacity: '1',
		// transition: 'opacity .25s ease-in-out',
		// '&:hover': {
		// 	opacity: '0.6',
		// },

		[theme.breakpoints.down('lg')]: {
			minHeight: 'auto',
			minWidth: 'auto',
		},
	},

	footerLogo: {
		[theme.breakpoints.down('lg')]: {
			textAlign: 'center',
		},
	},

	footerSocialContainer: {
		[theme.breakpoints.down('lg')]: {
			textAlign: 'center',
		},
	},
}));

export const Footer = () => {
	const classes = useStyles();
	const location = useLocation();

	const { footer } = useStaticQuery(graphql`
		query {
			footer: sanityFooterContent {
				fieldServiceSoftwareLinks {
					title
					slug {
						current
					}
				}
				additionalSolutionsLinks {
					title
					slug {
						current
					}
				}
				industryLinks {
					title
					slug {
						current
					}
				}
				companyLinks {
					title
					slug {
						current
					}
					internalLink
				}
				reachUsContent {
					salesNumber
					supportNumber
					address
				}
				wwFooterLogo {
					asset {
						url
						gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, width: 220)
					}
				}
			}
		}
	`);
	const med = useMediaQuery('(max-width: 960px)');
	return (
		<div>
			<AppBar position='static' className={classes.footer}>
				<Container fixed>
					<Toolbar style={{ padding: '0 0 3rem 0' }}>
						<Grid container direction='row'>
							{!location.pathname.includes(['/uc-toolkit']) ? (
								<>
									<Grid
										container
										direction='row'
										justify='space-evenly'
										alignItems='flex-start'
										spacing={4}
										style={{ textAlign: 'center', paddingTop: '0' }}>
										<Grid item xs={6} md={2}>
											<Grid
												container
												direction='column'
												justify='flex-start'
												alignItems='flex-start'>
												<Typography className={classes.headerText}>
													Products
												</Typography>
												{footer.fieldServiceSoftwareLinks.map(
													(fieldService, index) =>
														fieldService.internalLink ? (
															<Link
																key={index}
																to={`/${fieldService.slug.current}`}
																className={classes.linkText}>
																{fieldService.title}
															</Link>
														) : (
															<a
																key={index}
																target='_blank'
																rel='noopener'
																aria-label="Opens in a new tab"
																href={fieldService.slug.current}
																className={classes.linkText}>
																{fieldService.title}
															</a>
														)
												)}
											</Grid>
										</Grid>
										<Grid item xs={6} md={2}>
											<Grid
												container
												direction='column'
												justify='flex-start'
												alignItems='flex-start'>
												<Typography className={classes.headerText}>
													&nbsp;
												</Typography>
												{footer.additionalSolutionsLinks.map(
													(additionalSolution, index) =>
														additionalSolution.internalLink ? (
															<Link
																key={index}
																to={`/${additionalSolution.slug.current}`}
																className={classes.linkText}>
																{additionalSolution.title}
															</Link>
														) : (
															<a
																key={index}
																target='_blank'
																rel='noopener'
																aria-label="Opens in a new tab"
																href={additionalSolution.slug.current}
																className={classes.linkText}>
																{additionalSolution.title}
															</a>
														)
												)}
											</Grid>
										</Grid>
										<Grid item xs={6} md={2}>
											<Grid
												container
												direction='column'
												justify='flex-start'
												alignItems='flex-start'>
												<Typography className={classes.headerText}>
													Industries
												</Typography>{' '}
												{footer.industryLinks.map((industry, index) => (
													<Link
														key={index}
														to={`/${industry.slug.current}`}
														className={classes.linkText}>
														{industry.title}
													</Link>
												))}
											</Grid>
										</Grid>
										<Grid item xs={6} md={2}>
											<Grid
												container
												direction='column'
												justify='flex-start'
												alignItems='flex-start'>
												<Typography className={classes.headerText}>
													Company
												</Typography>
												{footer.companyLinks.map((company, index) =>
													company.internalLink ? (
														<Link
															key={index}
															to={`/${company.slug.current}`}
															className={classes.linkText}>
															{company.title}
														</Link>
													) : (
														<a
															key={index}
															target='_blank'
															rel='noopener'
															aria-label="Opens in a new tab"
															href={company.slug.current}
															className={classes.linkText}>
															{company.title}
														</a>
													)
												)}
											</Grid>
										</Grid>

										<Grid item xs={12} md={4}>
											<Grid
												container
												direction='column'
												justify='flex-start'
												alignItems='flex-start'
												style={{ maxWidth: '350px' }}>
												<Typography className={classes.headerText}>
													Reach Us
												</Typography>
												<a
													href={`tel:${footer.reachUsContent.salesNumber.replace('-','').replace('(','').replace(')','').replace(' ','')}`}
													style={{ textDecoration: 'none' }}>
													<Typography className={classes.linkText}>
														Sales: {footer.reachUsContent.salesNumber}
													</Typography>
												</a>
												<a
													href={`tel:${footer.reachUsContent.supportNumber.replace('-','').replace('(','').replace(')','').replace(' ','')}`}
													style={{ textDecoration: 'none' }}>
													<Typography className={classes.linkText}>
														Service: {footer.reachUsContent.supportNumber}
													</Typography>
												</a>
												<Typography
													className={classes.linkTextAddress}
													style={{ textAlign: 'left' }}>
													{footer.reachUsContent.address}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</>
							) : null}
							<Grid
								container
								direction='row'
								justify='space-evenly'
								alignItems='center'
								spacing={2}
								style={{ marginTop: '4rem' }}>
								<Grid item xs={12} lg={2} className={classes.footerLogo}>
									<Link to='/'>
										{footer.wwFooterLogo.asset.gatsbyImageData.images?.sources
											.length ? (
											<img
												src={footer.wwFooterLogo.asset.url}
												style={{
													height: '2rem',
													width: '',
													marginTop: '.5rem',
												}}
												alt='logo'
											/>
										) : (
											<GatsbyImage
												image={footer.wwFooterLogo.asset.gatsbyImageData}
												alt='logo'
												imgStyle={{ objectFit: 'contain' }}
											/>
										)}
									</Link>
								</Grid>
								<Grid
									item
									xs={12}
									lg={2}
									className={classes.footerSocialContainer}>
									<Link to='/contact-us/' className={classes.linkText}>
										Copyright© {new Date().getFullYear()}
									</Link>
								</Grid>
								<Grid
									item
									xs={12}
									lg={2}
									className={classes.footerSocialContainer}>
									<Link to='/privacy-policy/' className={classes.linkText}>
										Privacy Policy
									</Link>
								</Grid>
								<Grid
									item
									xs={12}
									lg={2}
									className={classes.footerSocialContainer}>
									<Link to='/terms-of-use/' className={classes.linkText}>
										Terms of Use
									</Link>
								</Grid>
								<Grid
									item
									xs={12}
									lg={2}
									className={classes.footerSocialContainer}>
									<Link to='/end-of-life-policy/' className={classes.linkText}>
										End of Life Policy
									</Link>
								</Grid>

								<Grid
									item
									xs={12}
									lg={2}
									className={classes.footerSocialContainer}>
									{' '}
									<a
										className={classes.linkTextSocial}
										href='https://www.facebook.com/WorkWaveCo/'
										target='_blank'
										rel='noopener noreferrer'
										aria-label="Opens in a new tab"
										style={{ textDecoration: 'none' }}>
										<FontAwesomeIcon
											title='facebook'
											style={{
												height: med ? 'auto' : '25px',
												width: med ? 'auto' : '25px',
												maxWidth: '25px',
												color: '#002D5C',
											}}
											icon={['fab', 'square-facebook']}
										/>
									</a>
									<a
										className={classes.linkTextSocial}
										href='https://twitter.com/WorkWave/'
										target='_blank'
										rel='noopener noreferrer'
										aria-label="Opens in a new tab"
										style={{ textDecoration: 'none' }}>
										{' '}
										<FontAwesomeIcon
											title='twitter'
											style={{
												height: med ? 'auto' : '25px',
												width: med ? 'auto' : '25px',
												maxWidth: '25px',
												color: '#002D5C',
												marginLeft: med ? '3px' : '5px',
											}}
											icon={['fab', 'square-x-twitter']}
										/>
									</a>
									<a
										className={classes.linkTextSocial}
										href='https://www.instagram.com/workwave/'
										target='_blank'
										rel='noopener noreferrer'
										aria-label="Opens in a new tab"
										style={{ textDecoration: 'none' }}>
										{' '}
										<FontAwesomeIcon
											title='instagram'
											style={{
												height: med ? 'auto' : '25px',
												width: med ? 'auto' : '25px',
												maxWidth: '25px',
												color: '#002D5C',
												marginLeft: med ? '2px' : '5px',
											}}
											icon={['fab', 'square-instagram']}
										/>
									</a>
									<a
										className={classes.linkTextSocial}
										href='https://www.youtube.com/user/MarathonDataLLC/'
										target='_blank'
										rel='noopener noreferrer'
										aria-label="Opens in a new tab"
										style={{ textDecoration: 'none' }}>
										<FontAwesomeIcon
											title='youtube'
											style={{
												height: med ? 'auto' : '25px',
												width: med ? 'auto' : '25px',
												maxWidth: '25px',
												color: '#002D5C',
												marginLeft: med ? '6px' : '9px',
											}}
											icon={['fab', 'square-youtube']}
										/>
									</a>
									<a
										className={classes.linkTextSocial}
										href='https://www.linkedin.com/company/workwave/'
										target='_blank'
										rel='noopener noreferrer'
										aria-label="Opens in a new tab"
										style={{ textDecoration: 'none' }}>
										{' '}
										<FontAwesomeIcon
											title='linkedin'
											style={{
												height: med ? 'auto' : '25px',
												width: med ? 'auto' : '25px',
												maxWidth: '25px',
												color: '#002D5C',
												marginLeft: med ? '3px' : '5px',
											}}
											icon={['fab', 'linkedin']}
										/>
									</a>
								</Grid>
							</Grid>
						</Grid>
					</Toolbar>
				</Container>
			</AppBar>
		</div>
	);
};
